@import url('https://fonts.googleapis.com/css2?family=Gaegu&display=swap');

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: 'Gaegu', sans-serif;
}

body {
    display: flex;
    flex-direction: column;
    background: url('../../public/static.gif') repeat;
    background-size: 250px 250px;
    background-color: rgba(255, 255, 255, 0.925);
    position: relative;
}

.main-content {
    width: 100%;
    min-height: 100vh; 
    flex: 1; 
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.925);
    padding-bottom: 40px;
}

.blog {
    padding-top: 140px;
    text-align: center;
    padding-bottom: 40px;
}

.blog h1 {
    font-size: 2.2rem;
}

.blog-menu-wrapper {
    display: flex;
    justify-content: center;
    margin-left: 40px;
    margin-right: 40px;
}

.blog-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: 'Gaegu', sans-serif;
    text-align: center;
    max-width: 400px;
    width: 100%;
}

.blog-menu li {
    margin: 10px 0;
}

.blog-menu li a {
    text-decoration: none;
    color: #000000;
    font-size: 1.3rem;
}

.blog-post-container {
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
    padding-top: 120px;
}

.blog-post {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: -5px;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Gaegu', sans-serif;
}

h1 {
    font-size: 2.2rem;
}

h2 {
    font-size: 1.5rem;
}

h3, h4, h5, p {
    font-size: 1.2rem;
}

h3 {
    margin-top: -20px;
    color: #676767;
}

h4 {
    text-align: center;
}
h6 {
    font-size: 1.2rem;
    margin-top: -17px;
    margin-bottom: 1px;
    color: rgb(119, 119, 119);
}

.back-button {
    display: inline-block;
    margin-left: 30px;
    text-decoration: none;
    color: #000000;
    font-family: 'Gaegu', sans-serif;
    font-size: 18px;
    cursor: pointer;
}

.blog-post img {
    display: block;
    margin: 0 auto;
    width: 50%;
    height: auto;
}