@import url('https://fonts.googleapis.com/css2?family=Gaegu&display=swap');

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: 'Gaegu', sans-serif;
}

.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh; 
    width: 100%;
    background-color: rgba(255, 255, 255, 0.925);
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

.contact h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    margin-top: 0px;
}

.contact-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
}

.linkedin-icon, .github-icon, .email-icon, .spotify-icon, .soundcloud-icon {
    width: 100px;
    height: 100px;
    transition: transform 0.3s;
}

.linkedin-icon {
    animation: linkedin-animation 0.7s steps(2) infinite;
}

@keyframes linkedin-animation {
    0% {
        content: url('../../public/icons/linkedin1.png');
    }
    50% {
        content: url('../../public/icons/linkedin2.png');
    }
    100% {
        content: url('../../public/icons/linkedin1.png');
    }
}

.github-icon {
    animation: github-animation 0.7s steps(2) infinite;
}

@keyframes github-animation {
    0% {
        content: url('../../public/icons/github1.png');
    }
    50% {
        content: url('../../public/icons/github2.png');
    }
    100% {
        content: url('../../public/icons/github1.png');
    }
}

.email-icon {
    animation: email-animation 0.7s steps(2) infinite;
}

@keyframes email-animation {
    0% {
        content: url('../../public/icons/email1.png');
    }
    50% {
        content: url('../../public/icons/email2.png');
    }
    100% {
        content: url('../../public/icons/email1.png');
    }
}

.spotify-icon {
    animation: spotify-animation 0.7s steps(2) infinite;
}

@keyframes spotify-animation {
    0% {
        content: url('../../public/icons/spotify1.png');
    }
    50% {
        content: url('../../public/icons/spotify2.png');
    }
    100% {
        content: url('../../public/icons/spotify1.png');
    }
}

.soundcloud-icon {
    animation: soundcloud-animation 0.7s steps(2) infinite;
}

@keyframes soundcloud-animation {
    0% {
        content: url('../../public/icons/soundcloud1.png');
    }
    50% {
        content: url('../../public/icons/soundcloud2.png');
    }
    100% {
        content: url('../../public/icons/soundcloud1.png');
    }
}

@media (max-width: 600px) {
    .contact-links {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
    .contact-links > *:nth-child(5) {
        grid-column: span 2;
        justify-self: center;
    }
}
