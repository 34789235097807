@import url('https://fonts.googleapis.com/css2?family=Gaegu&display=swap');

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden; 
    overflow-y: auto; 
    background: url('../../public/static.gif') repeat;
    background-size: 250px 250px; 
    width: 100vw;
    box-sizing: border-box;
    overscroll-behavior: none;
}

* {
    box-sizing: border-box;
}

.about-me {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: flex-start; 
    min-height: 100vh; 
    width: 100%;
    background-color: rgba(255, 255, 255, 0.925);
    text-align: left; 
    padding-top: 120px;
    font-family: 'Gaegu', sans-serif;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
}

.about-me h1, .about-me p {
    max-width: 600px;
    width: 100%; 
    margin: 0 auto; 
}

.about-me h1 {
    font-size: 2.2rem;
    margin-bottom: 20px;
}

.about-me p {
    font-size: 1.3rem;
    line-height: 1.5;
    margin-bottom: 20px;
}

.about-me .experiences-container ul {
    padding-left: 20px;
    margin-top: -15px;
}

.about-me .experiences-container li {
    margin-bottom: 0px; 
    padding-left: 0;
    list-style-type: disc; 
}

.about-me li p {
    margin: 0; 
    padding: 0; 
}

.about-container {
    display: flex;
    align-items: center;
    max-width: 800px;
    margin-bottom: 40px;
    margin-top: 20px;
}

.about-image {
    width: 150px;
    height: 150px;
    margin-left: 20px;
    animation: me-animation 1s steps(2) infinite;
}

@keyframes me-animation {
    0% {
        content: url('../../public/icons/me1.png');
    }
    50% {
        content: url('../../public/icons/me2.png');
    }
    100% {
        content: url('../../public/icons/me1.png');
    }
}

.experiences-container {
    display: flex;
    align-items: center;
    max-width: 800px;
    margin-bottom: 40px;
}

.experiences-image {
    width: 150px;
    height: 150px;
    margin-right: 30px;
    animation: experiences-animation 1s steps(2) infinite;
}

@keyframes experiences-animation {
    0% {
        content: url('../../public/icons/brain1.png');
    }
    50% {
        content: url('../../public/icons/brain2.png');
    }
    100% {
        content: url('../../public/icons/brain1.png');
    }
}


.about-container, .experiences-container, .projects-container {
    position: relative;
}

.about-container, .experiences-container, .projects-container {
    padding-top: 0px; 
}

.about-text, .experiences, .projects {
    opacity: 1;
    transition: opacity 0.5s ease-out;
}

.about-container.scrolled .about-text,
.experiences-container.scrolled .experiences,
.projects-container.scrolled .projects {
    opacity: 0;
}

.about-me .projects-container ul {
    padding-left: 20px;
    margin-top: -15px;
}

.about-me .projects-container li {
    margin-bottom: 0px; 
    padding-left: 0;
    list-style-type: disc;
}

.projects-container {
    display: flex;
    align-items: center;
    max-width: 800px;
    margin-bottom: 40px;
}

.projects-image {
    width: 150px;
    height: 150px;
    margin-left: 20px;
    animation: projects-animation 1s steps(2) infinite;
}

@keyframes projects-animation {
    0% {
        content: url('../../public/icons/nerd1.png');
    }
    50% {
        content: url('../../public/icons/nerd2.png');
    }
    100% {
        content: url('../../public/icons/nerd1.png');
    }
}

.about-me .projects a {
    text-decoration: none;
    color: #083ede;
    transition: color 0.3s ease
}

.about-me a:hover {
    color: #5875cc;
}

/* Media query to stack images below text on smaller screens */
@media (max-width: 768px) {
    .about-container, .experiences-container, .projects-container {
        flex-direction: column;
        text-align: left;
    }

    .about-image, .experiences-image, .projects-image {
        margin: 15px auto 0;
    }

    .about-image, .experiences-image, .projects-image {
        width: 150px; 
        height: 150px; 
    }

    .experiences-container {
        flex-direction: column;
    }

    .experiences {
        order: 1;
    }

    .experiences-image {
        order: 2;
    }
}


.mozilla {
    text-decoration: none;
    color: #E66000;
    transition: color 0.3s ease
}

.about-me .mozilla:hover {
    color: #f19c5f;
}

.cohere {
    text-decoration: none;
    color: #355146;
    transition: color 0.3s ease
}

.about-me .cohere:hover {
    color: #7d8c86
}

.rbc {
    text-decoration: none;
    color: #0059b3;
    transition: color 0.3s ease
}

.about-me .rbc:hover {
    color: #709dca;
}

.hatchery {
    text-decoration: none;
    color: #318888;
    transition: color 0.3s ease
}

.about-me .hatchery:hover {
    color: #8eb9b9;
}

.asana {
    text-decoration: none;
    color: #f06a6a;
    transition: color 0.3s ease
}

.about-me .asana:hover {
    color: #f59999;
}