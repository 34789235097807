@import url('https://fonts.googleapis.com/css2?family=Gaegu&display=swap');

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden; 
}

.home-icon-container {
    position: relative;
    top: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    z-index: 1000; 
}

.home-icon {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black; 
    white-space: nowrap; 
}

.home-icon img {
    width: 40px;
    height: 40px;
    margin-right: 15px;
    animation: frog-animation 1s steps(2) infinite;
}

.home-icon span {
    font-family: 'Gaegu', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    display: inline-block; 
    white-space: nowrap;
}

@keyframes frog-animation {
    0% {
        content: url('../../public/icons/frog1.png');
    }
    50% {
        content: url('../../public/icons/frog2.png');
    }
    100% {
        content: url('../../public/icons/frog1.png');
    }
}

@media (max-width: 450px) {
    .home-icon {
        left: 50%;
        transform: translateX(-58%);
    }
}